<template>
   <v-dialog  max-width="400" >
      <template v-slot:activator="{ on, attrs }">
         <v-btn v-on="on" v-bind="attrs" color="error" class="form-btn ma-2 m-auto px-8" :class="btnClass" large :disabled="isFetching">Remove</v-btn>
      </template>
      <template v-slot:default="dialog">
         <v-card class="py-3">
            <v-card-text class="d-flex flex-column justify-center align-center">
               <v-avatar class="mt-2" icon size="50">
                  <v-icon size="3rem" color="error">{{ icons.mdiAlertCircleOutline }}</v-icon>
               </v-avatar>
               <span class="text-h6 mt-2">Confirm Remove?</span>
               <v-row class="mt-2">
                  <v-col cols="6">
                     <v-btn color="secondary" class="m-auto white--text form-btn" block x-large @click.prevent="dialog.value=false">Cancel</v-btn>
                  </v-col>
                  <v-col cols="6">
                     <v-btn color="error" class="m-auto white--text form-btn" block x-large @click.prevent="removeAction(dialog)">Yes</v-btn>
                  </v-col>
               </v-row>
            </v-card-text>
         </v-card>
      </template>
   </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { mdiAlertCircleOutline } from '@mdi/js'

   export default {
      name: 'FormDeleteBtn',
      props: ["removeAction", "btnClass"],
      setup() {
         return {
            icons: {
               mdiAlertCircleOutline 
            }
         }
      },
      computed: {
         ...mapState({
            isFetching: (state) => state.request.isFetching,
            isSuccess: (state) => state.request.isSuccess,
            isFail: (state) => state.request.isFail,
         }),
      },
      
   }
</script>