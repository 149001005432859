<template>
   <v-menu top :offset-y="true" :close-on-click="false" :close-on-content-click="false" v-model="qrCodeBtn">
      <template v-slot:activator="{ on, attrs }">
         <v-btn color="purple darken-3 white--text" 
            class="ma-2 mx-3 m-auto report-btn" 
            large 
            v-bind="attrs" 
            v-on="on" 
            :disabled="isFetching">QR code</v-btn>
      </template>
      <v-card>
         <v-card-title class="my-0 pa-0">
            <v-spacer></v-spacer>
            <v-btn icon @click="qrCodeBtn=false" >
               <v-icon>{{icons.mdiClose}}</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <v-img :src="url" 
               max-height="200"
               max-width="200"/>
         </v-card-text>
      </v-card>
   </v-menu>
</template>

<script>
import { mapState } from "vuex";
import { mdiClose } from '@mdi/js'

   export default {
      name: 'FormButtonQRCode',
      components: {
      },
      props: ["url"],
      setup() {
         return {
            icons: {
               mdiClose
            }
         }
      },
      data() {
         return {
            qrCodeBtn: false,
         }
      },
      computed: {
         ...mapState({
            isFetching: (state) => state.request.isFetching,
            isSuccess: (state) => state.request.isSuccess,
            isFail: (state) => state.request.isFail,
         }),
      },
   }
</script>