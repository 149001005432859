<template>
   <v-row>
      <v-col md="6" cols="12">
         <v-file-input
            v-show="false"
            ref="image"
            hide-details="auto"
            persistent-placeholder
            outlined
            prepend-icon=""
            append-icon=""
            @change="onFilePicked"
            accept="image/png, image/jpeg, image/bmp, application/pdf"
            :disabled="isFetching || disabled" />
         <v-text-field
            v-show="showInput"
            :value='value'
            :label="label"
            :required="required"
            type="text"
            outlined
            readonly
            hide-details="auto"
            persistent-hint
            :rules="rules.inputRules" />
      </v-col>
      <v-col md="6" cols="12" class="d-flex">
         <v-btn
            v-show="!readonly"
            class="form-btn ma-2 m-auto px-8" 
            color="purple darken-3 white--text" 
            @click="handleFileImport" 
            :disabled="isFetching || disabled"
         >
            Upload
         </v-btn>
         
         <v-dialog
            v-model="dialog"
            max-width="800"
         >
            <template v-slot:activator="{ on, attrs }">
               <v-btn
                  v-show="showView"
                  class="form-btn ma-2 m-auto px-8" 
                  color="purple darken-3 white--text" 
                  v-bind="attrs"
                  v-on="on"
               >
                  View
               </v-btn>
            </template>
            <v-card>
               <v-card-title class="text-h5">
                  <v-img
                     ref="imgPreview"
                     class="img-preview"
                     :src="this.$getMediaUrl(value)"
                     contain
                  />
               </v-card-title>
               <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                     class="form-btn" 
                     color="purple darken-3 white--text" 
                     text
                     @click="dialog = false"
                  >
                     Close
                  </v-btn>
               </v-card-actions>
            </v-card>
         </v-dialog>
      </v-col>
   </v-row>

</template>

<script>
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'

export default {
   name: 'ImageButtonUpload',
   emits: ['onUploaded'],
   props: {
      label: null,
      value: null,
      buttonLink: null,
      placeHolder: null,
      disabled: Boolean,
      required: Boolean,
      readonly: {
         default: false,
         type: Boolean,
      },
      showView: {
         default: true,
         type: Boolean,
      },
      showInput: {
         default: true,
         type: Boolean,
      },
   },
   data() {
      return {    
         dialog: false,
         rules: {
            inputRules: [
               v => this.required ? (!!v || 'This field cannot be empty') : true,
            ]
         }
      }
   },
   computed: {
      ...mapState({
         state: (state) => state,
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['uploadImage'], 'request'),
      handleFileImport() {
         this.$refs.image.$refs.input.click()
      },
      async onFilePicked(file) {
         console.log('file', file)
         if (!!file) {
            const resp = await this.uploadImage({ file })
            console.log('onFilePicked', resp)
            if (!resp) return
            this.$emit('input', resp.filename)
            this.$emit('onUploaded', resp)
         }
      },
   }
}
</script>

<style lang='scss' scoped>
.form-text {
   // border: 1px solid #eee;
   position: relative;
   padding-left: 11px;
   min-height: 55px;

   .form-label {
      background: #fff;
      position: absolute;
      top: -11px;
      padding-inline-start: 2px;
      padding-inline-end: 2px;
   }

   span.text {
      position: absolute;
      bottom: 16px;
   }

   div.text {
      position: absolute;
      bottom: 0;
   }

   &.bordered {
      border-collapse: collapse;
      border-color: rgba(94, 86, 105, 0.22);
      border-style: solid;
      border-width: 1px;
      border-radius: 5px;
   }
}
.v-input--is-readonly {   
   background-color: #f8f8f8;
}
</style>