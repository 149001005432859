<template>
   <v-row>
      <v-col lg="8" md="6" cols="12">
         <v-file-input
            v-show=false
            ref="image"
            :label="label"
            hide-details="auto"
            persistent-placeholder
            :placeholder="placeHolder"
            :rules="rules.uploadRules"
            outlined
            prepend-icon=""
            append-icon=""
            @change="onFilePicked"
            accept="image/png, image/jpeg, image/bmp, application/pdf"
            :disabled="isFetching || disabled"
         />
         <v-text-field
            :value='value'
            :label="label"
            type="text"
            outlined
            readonly
            hide-details="auto"
            persistent-hint
            :rules="rules.inputRules"
         />
      </v-col>
      <v-col lg="4" md="6" cols="12" class="d-flex">
         <v-btn class="ml-2 align-self-center" 
            color="purple darken-3 white--text" 
            @click="handleFileImport" 
            :disabled="isFetching || disabled">
            Upload
         </v-btn>
         <v-btn class="ml-2 align-self-center" 
            color="purple darken-3 white--text" 
            :disabled="isFetching || disabled" 
            :href="buttonLink" 
            target="_blank"
            v-if="buttonLink">
            View
         </v-btn>
      </v-col>
   </v-row>

</template>

<script>
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'

export default {
   name: 'FormButtonUpload',
   props: ['placeHolder', 'label', 'value', 'buttonLink', 'disabled'],
   data() {
      return {
         image: '',
         rules: {
            uploadRules: [
               v => this.required ? (!!v && v.size < 2000000 || 'File size should be less than 2 MB') : true,
            ],
            inputRules: [
               v => this.required ? (!!v || 'This field cannot be empty') : true,
            ]
         }
      }
   },
   computed: {
      ...mapState({
         state: (state) => state,
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['sendRequest']),
      handleFileImport() {
         this.$refs.image.$refs.input.click()
      },
      async onFilePicked(file) {
         console.log('file', file)
         if (!!file) {
            const resp = await this.sendRequest({
               type: 'upload_image',
               data: { forceJPG: true },
               file,
            })
            console.log('resp', resp)
            if (!resp) return
            this.image = _.get(resp, '[1].filename')
            this.$emit('input', this.image)
         }
         // if (file != null) {
         //    const imageName = file.name
         //    if (imageName.lastIndexOf('.') <= 0) {
         //       return
         //    }
         //    this.$emit('input', file)
         // } else {
         //    this.$emit('input', null)
         // }
      }
   }
}
</script>
<style lang='scss' scoped>
.form-text {
   // border: 1px solid #eee
   position: relative;
   padding-left: 11px;
   min-height: 55px;

   .form-label {
      background: #fff;
      position: absolute;
      top: -11px;
      padding-inline-start: 2px;
      padding-inline-end: 2px;
   }

   span.text {
      position: absolute;
      bottom: 16px;
   }

   div.text {
      position: absolute;
      bottom: 0;
   }

   &.bordered {
      border-collapse: collapse;
      border-color: rgba(94, 86, 105, 0.22);
      border-style: solid;
      border-width: 1px;
      border-radius: 5px;
   }
}
</style>