<template>
   <v-form ref="voucher-package-form" @submit.prevent="formSubmit">
      <v-row>
         <v-col cols="12">
            <h3>Voucher Package</h3>
         </v-col>
         <v-col cols="12">
            <v-btn color="secondary" class="m-auto px-8" large @click.prevent="$router.go(-1)" :disabled="isFetching">Back</v-btn>
         </v-col>
         <v-col cols="12">
            <v-card>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12">
                        <ImageBtnUpload v-model="data.cover_image" label="Cover image" :required="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                     </v-col>
                     <!-- <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.id" label="Voucher ID" :required="true" />
                     </v-col> -->
                     <v-col md="6" cols="12">
                        <FormControl inputType="select" v-model="data.category_id" label="Service" :required="true" :options="dataOptions.category" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="number" v-model="data.active_day" label="Active day" :required="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.title" label="Voucher title" :required="true" />
                     </v-col>
                     <!-- <v-col md="6" cols="12">
                        <FormControl inputType="select" v-model="data.status" label="Active/inactive" :required="true" :options="dataOptions.itemStatus" />
                     </v-col> -->
                     <v-col md="6" cols="12">
                        <FormControl inputType="number" v-model="data.price" label="Price" :required="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="number" v-model="data.vip_price" label="VIP price" :required="true" />
                     </v-col>
                     <v-col md="12" cols="12">
                        <FormControl inputType="textarea" v-model="data.description" label="description" :rows="8" :required="true" />
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         <v-col md="12" cols="12">
            <v-card>
               <v-card-title><h5>Service</h5></v-card-title>
               <v-card-text>
                  <v-row>
                     <v-col md="12" cols="12">
                        <v-row v-for="(voucher, index) in data.vouchers" :key="`provider-item-${index}`">
                           <v-col md="5" cols="12">
                              <FormControl inputType="select" v-model="voucher.service" label="Service" :required="true" readonly :options="dataOptions.voucherType" />
                           </v-col>
                           <v-col md="5" cols="12">
                              <FormControl inputType="number" v-model="voucher.quantity" label="Quantity" :required="true" />
                           </v-col>
                           <!-- <v-col md="2" cols="12" class="d-flex">
                              <v-btn color="error" class="align-self-center" @click="removeRow(index)" :disabled="isFetching">Remove</v-btn>
                           </v-col> -->
                           <v-col md="12" cols="12">
                              <v-divider class=""></v-divider>
                           </v-col>
                        </v-row>

                     </v-col>
                     <!-- <v-col cols="12">
                        <v-btn color="secondary" :disabled="isFetching" @click="createRow()">Add</v-btn>
                     </v-col> -->
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         <!-- <v-col cols="12">
            <FormButtonQRCode :url="qrCodeUrl" />
         </v-col> -->
         <v-col cols="12">
            <v-btn color="primary" class="px-8 form-btn" large :disabled="isFetching" type="submit">Save</v-btn>
            <FormDeleteBtn :removeAction="removeItem" v-if="!isNew" />
         </v-col>
      </v-row>
   </v-form>
</template>

<script>
import FormButtonQRCode from '@/components/form/FormButtonQRCode'
import FormButtonUpload from '@/components/form/FormButtonUpload'
import FormDeleteBtn from '@/components/form/FormDeleteBtn'
import ImageBtnUpload from '@/components/form/ImageBtnUpload'
import FormControl from '@/components/form/FormControl'
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'

export default {
   name: 'VoucherPackageDetail',
   components: {
      FormControl,
      FormButtonUpload,
      FormDeleteBtn,
      FormButtonQRCode,
      ImageBtnUpload,
   },
   data() {
      return {
         isNew: true,
         data: {
            id: _.toNumber(this.$route.params.id),
            category_id: null,
            active_day: '',
            title: '',
            status: '',
            price: '',
            vip_price: '',
            description: '',
            cover_image: null,
            qrCodeUrl: '',
            vouchers: [
               { id: 0, service: 'hardware_quantity', quantity: 0 },
               { id: 1, service: 'repair_quantity', quantity: 0 },
               { id: 2, service: 'screen_quantity', quantity: 0 },
            ],
            hardware_quantity: 0,
            repair_quantity: 0,
            screen_quantity: 0,
         },
         dataOptions: {
            category: [],
            voucherType: [
               { text: '硬件檢查服務', value: 'hardware_quantity' },
               { text: '電腦維修服務', value: 'repair_quantity' },
               { text: '電腦螢幕檢查', value: 'screen_quantity' },
            ],
         }
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['sendRequest'], 'request'),
      ...mapActions(['setDialogMessage', 'setShowDialog']),

      async initData() {
         const resp = await this.sendRequest({ type: 'get_voucher_package_by_id', data: { id: this.data.id } })
         if (!resp) return
         this.isNew = false
         this.data = {
            ...this.data,
            title: resp.data.title,
            category_id: resp.data.category_id,
            active_day: resp.data.active_day,
            title: resp.data.title,
            price: resp.data.price,
            vip_price: resp.data.vip_price,
            description: resp.data.description,
            cover_image: resp.data.cover_image,
            vouchers: [
               { id: 0, service: 'hardware_quantity', quantity: resp.data.hardware_quantity },
               { id: 1, service: 'repair_quantity', quantity: resp.data.repair_quantity },
               { id: 2, service: 'screen_quantity', quantity: resp.data.screen_quantity },
            ]
         }
      },
      async fetchOption() {
         const resp = await this.sendRequest({ type: 'get_voucher_category', data: {} })
         if (!resp) return
         this.dataOptions.category = _.map(resp.data, cat => ({
            text: cat.title,
            value: cat.id,
         }))
      },
      createRow() {
         this.$set(this.data.vouchers, this.data.vouchers.length, { id: null, service: '', quantity: '' })
      },

      removeRow(index) {
         console.log('remove item', index)
         this.$delete(this.data.vouchers, index)
      },

      async formSubmit() {
         console.log('save')
         const isValid = this.$refs['voucher-package-form'].validate()
         if (isValid) {
            const action = this.isNew ? this.createItem : this.updateItem
            const resp = await action()
            this.setDialogMessage({ 
               message: resp ? 'Successful' : 'Failed', 
               isError: !resp, 
               returnLink: (this.data.id === undefined ? { name: 'voucherPackage' } : null )
            })
            this.setShowDialog(true)
            if (!!resp && this.isNew) {
               this.isNew = false
               this.data.id = resp.data.id
               this.initData()
               this.$router.replace({ name: 'voucherPackageDetail', params: { id: resp.data.id } })
            }
         }
      },
      async createItem() {
         console.log('create')
         const formdata = {
            status: 'active',
            data_status: 'active',
            title: this.data.title,
            category_id: this.data.category_id,
            description: this.data.description,
            cover_image: this.data.cover_image,
            active_day: _.toNumber(this.data.active_day),
            price: _.toNumber(this.data.price),
            vip_price: _.toNumber(this.data.vip_price),
            hardware_quantity: _.toNumber(this.data.vouchers[0].quantity),
            repair_quantity: _.toNumber(this.data.vouchers[1].quantity),
            screen_quantity: _.toNumber(this.data.vouchers[2].quantity),
         }
         return await this.sendRequest({ type: 'add_voucher_package', data: formdata })
      },
      async updateItem() {
         console.log('update')
         const formdata = {
            data_type: 'voucher_package_data',
            id: this.data.id,
            title: this.data.title,
            category_id: this.data.category_id,
            description: this.data.description,
            cover_image: this.data.cover_image,
            active_day: _.toNumber(this.data.active_day),
            price: _.toNumber(this.data.price),
            vip_price: _.toNumber(this.data.vip_price),
            hardware_quantity: _.toNumber(this.data.vouchers[0].quantity),
            repair_quantity: _.toNumber(this.data.vouchers[1].quantity),
            screen_quantity: _.toNumber(this.data.vouchers[2].quantity),
         }
         return await this.sendRequest({ type: 'update_voucher_package', data: formdata })
      },
      async removeItem(dialog) {
         console.log('delete')
         dialog.value = false
         const formdata = {
            data_type: 'voucher_package_data',
            id: this.data.id,
            status: 'inactive',
         }
         const resp = await this.sendRequest({ type: 'update_voucher_package', data: formdata })
         if (!resp) {
            this.setDialogMessage({ 
               message: 'Failed', 
               isError: true,
            })
            this.setShowDialog(true)
            return
         }
         this.$router.push({name: 'voucherPackage'})
      },
   },
   async mounted() {
      await Promise.all([
         this.initData(),
         this.fetchOption(),
      ])
   }
}
</script>
