<template>
   <v-text-field v-if="inputType=='string'"
      v-bind="$attrs"
      color="purple darken-3"
      persistent-placeholder
      :value='value'
      @input='$emit("input", $event)'
      :label="label"
      :placeholder="placeHolder"
      hide-details="auto"
      outlined
      :required="required"
      :rules="rules.textRules"
      :disabled="isFetching || disabled"
      :readonly="readonly" />
   
   <v-text-field v-else-if="inputType=='number'"
      v-bind="$attrs"
      color="purple darken-3"
      persistent-placeholder
      :value='value'
      @input='$emit("input", $event)'
      :label="label"
      :placeholder="placeHolder"
      hide-details="auto"
      outlined
      :required="required"
      :rules="rules.numberRules"
      :disabled="isFetching || disabled"
      type="number"
      :readonly="readonly" />
   
   <v-text-field v-else-if="inputType=='phone'"
      v-bind="$attrs"
      color="purple darken-3"
      persistent-placeholder
      :value='value'
      @input='$emit("input", $event)'
      :label="label"
      :placeholder="placeHolder"
      hide-details="auto"
      outlined
      :required="required"
      :rules="rules.phoneRules"
      :disabled="isFetching || disabled"
      type="number"
      :readonly="readonly" />

   <v-text-field v-else-if="inputType=='email'"
      v-bind="$attrs"
      color="purple darken-3"
      persistent-placeholder
      :value='value'
      @input='$emit("input", $event)'
      :label="label"
      :placeholder="placeHolder"
      hide-details="auto"
      outlined
      :required="required"
      :rules="rules.emailRules"
      :disabled="isFetching || disabled"
      type="email"
      :readonly="readonly" />

   <v-text-field v-else-if="inputType=='password'"
      v-bind="$attrs"
      color="purple darken-3"
      persistent-placeholder
      :value='value'
      @input='$emit("input", $event)'
      :label="label"
      :placeholder="placeHolder"
      hide-details="auto"
      outlined
      :required="required"
      :rules="rules.password"
      :disabled="isFetching || disabled"
      type="password"
      :readonly="readonly" />

   <v-textarea v-else-if="inputType=='textarea'"
      v-bind="$attrs"
      color="purple darken-3"
      persistent-placeholder
      :value='value'
      @input='$emit("input", $event)'
      :label="label"
      :placeholder="placeHolder"
      :rows="rows ? rows : 3"
      hide-details="auto"
      outlined
      :required="required"
      :rules="rules.textRules"
      :disabled="isFetching || disabled"
      :readonly="readonly" />

   <div v-else-if="inputType=='checkbox' && options">
      <v-checkbox v-for="(item, index) in options" :key="`checkbox-${index}`"
         v-bind="$attrs"
         class="my-0 py-0"
         color="purple darken-3"
         v-model="value"
         :value='item.value'
         @change='$emit("input", value)'
         :label="item.text"
         :rules="rules.checkboxRules"
         :disabled="isFetching || disabled" />
   </div>
   <div v-else-if="inputType=='checkbox' && !options">
      <v-checkbox
         v-bind="$attrs"
         class="my-0 py-0"
         color="purple darken-3"
         :value='value'
         @change="$emit('input', $event)"
         :label="label"
         :rules="rules.textRules"
         :disabled="isFetching || disabled" />
   </div>

   <v-select v-else-if="inputType=='select'"
      v-bind="$attrs"
      color="purple darken-3"
      persistent-placeholder
      :items="options"
      :value='value'
      @input='$emit("input", $event)'
      item-text="text"
      item-value="value"
      :label="label"
      outlined
      :placeholder="placeHolder"
      hide-details="auto"
      :disabled="isFetching || disabled"
      no-data-text="No data"
      :readonly="readonly"
      :required="required"
      :rules="rules.textRules"
      :menu-props="{ bottom: true, offsetY: true }" />
   
   <v-menu v-else-if="inputType=='dateRangePicker'"
      refs="menu-date-range-picker"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
         color="purple darken-3"
         persistent-placeholder
         :value='dateRangeValue'
         :label="label"
         :placeholder="placeHolder"
         :append-icon="icons.mdiCalendar"
          readonly
          v-bind="attrs"
          v-on="on"
         hide-details="auto"
         :rules="rules.dateRangeRules"
         :disabled="isFetching || disabled" 
         outlined />
      </template>
      <v-date-picker 
         v-bind="$attrs"
         color="purple darken-3"
         :value='value'
         @input='$emit("input", $event)'
         range
         no-title
         :disabled="isFetching || disabled" />
   </v-menu>
   <v-menu v-else-if="inputType=='datePicker'"
      refs="menu-date-picker"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
         :value='value'
         :label="label"
         :append-icon="icons.mdiCalendar"
         readonly
         v-bind="attrs"
         v-on="on"
         hide-details="auto"
         persistent-placeholder
         :placeholder="placeHolder"
         :rules="rules.dateRules"
         :disabled="isFetching || disabled" 
         outlined />
      </template>
      <v-date-picker 
         color="purple darken-3"
         v-bind="$attrs" 
         :value='value' 
         @input='$emit("input", $event)' 
         no-title 
         :disabled="isFetching || disabled" />
   </v-menu>

   <v-menu v-else-if="inputType == 'timePicker'"
      ref="timePickerMenu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value='value'
            :label="label"
            :append-icon="icons.mdiClockTimeFourOutline"
            readonly
            hide-details="auto"
            v-bind="attrs"
            v-on="on"
            persistent-placeholder
            :placeholder="placeHolder"
            :rules="rules.timeRules"
            :disabled="isFetching || disabled" 
            outlined />
        </template>
        <v-time-picker
         v-if="menu"
         :value='value' 
         full-width
         @input='$emit("input", $event)' 
         no-title 
         :disabled="isFetching || disabled" />
      </v-menu>

      <v-file-input v-else-if="inputType == 'upload'"
         ref="image"
         :label="label"
         hide-details="auto"
         persistent-placeholder
         :placeholder="placeHolder"
         :rules="rules.uploadRules"
         outlined
         prepend-icon=""
         :append-icon="icons.mdiPaperclip"
         @change="onFilePicked"
         accept="image/png, image/jpeg, image/bmp, application/pdf"
         :disabled="isFetching || disabled" />

</template>

<script>
import { mapState } from 'vuex';
import { mdiCalendar, mdiClockTimeFourOutline, mdiPaperclip } from '@mdi/js'

   export default {
      name: 'FormControl',
		props: [
         'placeHolder',
			'label',
			'id',
			'inputType',
			'dateTimePickerOptions',
			'able',
			'value',
			'selectData',
			'options',
         'required',
         'readonly',
         'disabled',
         'rows'
		],
      setup() {
         return {
            icons: {
               mdiCalendar,
               mdiClockTimeFourOutline,
               mdiPaperclip
            }
         }
      },
      data() {
         return {
            menu: false,
            rules: {
               textRules: [
                  (v) => this.required ? (!!v || "This field cannot be empty") : true,
               ],
               numberRules: [
                  (v) => this.required ? ( v !== '' || "This field cannot be empty") : true,
               ],
               phoneRules: [
                  (v) => this.required ? (!!v || "This field cannot be empty") : true,
                  (v) => (/\d{8}/.test(v) || "Invalid phone no. format"),
               ],
               emailRules: [
                  (v) => this.required ? (!!v || "This field cannot be empty") : true,
                  (v) => (/.+@.+\..+/.test(v) || "Invalid email format"),
               ],
               password: [
                  (v) => this.required ? (!!v || "This field cannot be empty") : true,
                  (v) => (/(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}/.test(v) || "Invalid password format"),
               ],
               dateRules: [
                  (v) => this.required ? (!!v || "This field cannot be empty") : true,
               ],
               dateRangeRules: [
                  (v) => this.required ? (!!v || "This field cannot be empty") : true,
               ],
               timeRules: [
                  (v) => this.required ? (!!v || "This field cannot be empty") : true,
               ],
               uploadRules: [
                  v => this.required ? (!!v && v.size < 2000000 || 'File size should be less than 2 MB') : true,
               ]
            }
         }
      },
      computed: {
         ...mapState({
            state: (state) => state,
            isFetching: (state) => state.request.isFetching,
            isSuccess: (state) => state.request.isSuccess,
            isFail: (state) => state.request.isFail,
         }),
         dateRangeValue() {
            return this.value ? this.value.join(' - ') : ''
         }
      },
      methods: {
         onFilePicked(file) {
            if (file != null) {
               const imageName = file.name
               if (imageName.lastIndexOf('.') <= 0) {
                  return
               }
               this.$emit('input', file);
            } else {
               this.$emit('input', null);
            }
         }
      }
   }
</script>

<style lang='scss' scoped>
.v-input--is-readonly {   
   background-color: #f8f8f8;
}
</style>